import React from "react";
import { Page } from "../../components/Page";
import { Title } from "../../components/Title";
import { Content } from "../../components/Content";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Contact } from "../../components/Contact";

const FeedbackPage = () => (
  <Page language="sv" page="Ge feedback">
    <Row>
      <Col>
        <Title>Ge feedback</Title>

        <Content>
          Du kan ge feedback via e-post till <Contact /> eller genom att direkt
          kontakta de stadsspecifika återkopplingskanalerna:
        </Content>

        <Content as="ul">
          <li>
            <a href="https://www.hel.fi/helsinki/sv/stad-och-forvaltning/delta/feedback">
              Helsingfors
            </a>
          </li>
        </Content>
      </Col>
    </Row>
  </Page>
);

export default FeedbackPage;
